<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="posting_period">Posting Period</vs-th>
        <vs-th sort-key="vendor_name">Vendor Name</vs-th>
        <vs-th sort-key="user_name">User Name</vs-th>
        <vs-th sort-key="vendor">Vendor</vs-th>
        <vs-th sort-key="assignment">Assignment</vs-th>
        <vs-th sort-key="doc_number">Document Number</vs-th>
        <vs-th sort-key="doc_type">Document Type</vs-th>
        <vs-th sort-key="posting_key">Posting Key</vs-th>
        <vs-th sort-key="doc_header_text">Document Header Text</vs-th>
        <vs-th sort-key="fiscal_year">Fiscal Year</vs-th>
        <vs-th sort-key="baseline_payment_date">Baseline Payment Date</vs-th>
        <vs-th sort-key="entry_date">Entry Date</vs-th>
        <vs-th sort-key="posting_date">Posting Date</vs-th>
        <vs-th sort-key="doc_date">Document Date</vs-th>
        <vs-th sort-key="payment_date">Payment Date</vs-th>
        <vs-th sort-key="net_due_date">Net Due Date</vs-th>
        <vs-th sort-key="date">Date</vs-th>
        <vs-th sort-key="current">Current</vs-th>
        <vs-th sort-key="from_0_to_15_days">0-15 days</vs-th>
        <vs-th sort-key="from_16_to_30_days">16-30 days</vs-th>
        <vs-th sort-key="from_31_to_60_days">31-60 days</vs-th>
        <vs-th sort-key="from_61_to_90_days">61-90 days</vs-th>
        <vs-th sort-key="from_91_to_180_days">91-180 days</vs-th>
        <vs-th sort-key="up_to_181_days">>=181 days</vs-th>
        <vs-th sort-key="sum_total_current">Sum of Amount in doc. cur.</vs-th>
        <vs-th sort-key="refference">Refference</vs-th>
        <vs-th sort-key="invoice_refference">Invoice Refference</vs-th>
        <vs-th sort-key="bussiness_area">Bussiness Area</vs-th>
        <vs-th sort-key="amount_in_doc_cur">Amount in doc. cur.</vs-th>
        <vs-th sort-key="doc_currency">Document Currency</vs-th>
        <vs-th sort-key="amount_in_local_currency">Amount in local currency</vs-th>
        <vs-th sort-key="local_currency">Local Currency</vs-th>
        <vs-th sort-key="tax_code">Tax Code</vs-th>
        <vs-th sort-key="special_g_l_ind">Special G/L ind.</vs-th>
        <vs-th sort-key="text">Text</vs-th>
        <vs-th sort-key="g_l_account">G/L Account</vs-th>
        <vs-th sort-key="company_code">Company Code</vs-th>
        <vs-th sort-key="trading_partner">Trading Partner</vs-th>
        <vs-th sort-key="profit_center">Profit Center</vs-th>
        <vs-th sort-key="clearing_doc">Clearing Document</vs-th>
        <vs-th sort-key="account">Account</vs-th>
        <vs-th sort-key="account_type">Account Type</vs-th>
        <vs-th sort-key="reserved_with">Reserved with</vs-th>
        <vs-th sort-key="discount_1_due_date_symbol">Discount 1 due date symbol</vs-th>
        <vs-th sort-key="discount_percentage_1">Discount Percent 1</vs-th>
        <vs-th sort-key="discount_percentage_2">Discount Percent 2</vs-th>
        <vs-th sort-key="transaction_code">Transaction Code</vs-th>
        <vs-th sort-key="transaction_type">Transaction Type</vs-th>
        <vs-th sort-key="terms_of_payment">Terms of Payment</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].vendor_code">{{
            data[indextr].vendor_code
          }}</vs-td>
          <vs-td :data="data[indextr].vendor_name">{{
            data[indextr].vendor_name
          }}</vs-td>
          <vs-td :data="data[indextr].user_name">{{
            data[indextr].user_name
          }}</vs-td>
          <vs-td :data="data[indextr].vendor">{{
            data[indextr].vendor
          }}</vs-td>
          <vs-td :data="data[indextr].assignment">{{
            data[indextr].assignment
          }}</vs-td>
          <vs-td :data="data[indextr].doc_number">{{
            data[indextr].doc_number
          }}</vs-td>
          <vs-td :data="data[indextr].doc_type">{{
            data[indextr].doc_type
          }}</vs-td>
          <vs-td :data="data[indextr].posting_key">{{
            data[indextr].posting_key
          }}</vs-td>
          <vs-td :data="data[indextr].doc_header_text">{{
            data[indextr].doc_header_text
          }}</vs-td>
          <vs-td :data="data[indextr].fiscal_year">{{
            data[indextr].fiscal_year
          }}</vs-td>
          <vs-td :data="data[indextr].baseline_payment_date">{{
            data[indextr].baseline_payment_date
          }}</vs-td>
          <vs-td :data="data[indextr].entry_date">{{
            data[indextr].entry_date
          }}</vs-td>
          <vs-td :data="data[indextr].posting_date">{{
            data[indextr].posting_date
          }}</vs-td>
          <vs-td :data="data[indextr].doc_date">{{
            data[indextr].doc_date
          }}</vs-td>
          <vs-td :data="data[indextr].payment_date">{{
            data[indextr].payment_date
          }}</vs-td>
          <vs-td :data="data[indextr].net_due_date">{{
            data[indextr].net_due_date
          }}</vs-td>
          <vs-td :data="data[indextr].date">{{
            data[indextr].date
          }}</vs-td>
          <vs-td :data="data[indextr].current">{{
            data[indextr].current
          }}</vs-td>
          <vs-td :data="data[indextr].from_0_to_15_days">{{
            data[indextr].from_0_to_15_days
          }}</vs-td>
          <vs-td :data="data[indextr].from_16_to_30_days">{{
            data[indextr].from_16_to_30_days
          }}</vs-td>
          <vs-td :data="data[indextr].from_31_to_60_days">{{
            data[indextr].from_31_to_60_days
          }}</vs-td>
          <vs-td :data="data[indextr].from_61_to_90_days">{{
            data[indextr].from_61_to_90_days
          }}</vs-td>
          <vs-td :data="data[indextr].from_91_to_180_days">{{
            data[indextr].from_91_to_180_days
          }}</vs-td>
          <vs-td :data="data[indextr].up_to_181_days">{{
            data[indextr].up_to_181_days
          }}</vs-td>
          <vs-td :data="data[indextr].up_to_181_days">{{
            data[indextr].up_to_181_days
          }}</vs-td>
          <vs-td :data="data[indextr].sum_total_current">{{
            formatPrice(data[indextr].sum_total_current)
          }}</vs-td>
          <vs-td :data="data[indextr].refference">{{
            data[indextr].refference
          }}</vs-td>
          <vs-td :data="data[indextr].invoice_refference">{{
            data[indextr].invoice_refference
          }}</vs-td>
          <vs-td :data="data[indextr].bussiness_area">{{
            data[indextr].bussiness_area
          }}</vs-td>
          <vs-td :data="data[indextr].amount_in_doc_cur">{{
            formatPrice(data[indextr].amount_in_doc_cur)
          }}</vs-td>
          <vs-td :data="data[indextr].doc_currency">{{
            data[indextr].doc_currency
          }}</vs-td>
          <vs-td :data="data[indextr].amount_in_local_currency">{{
            data[indextr].amount_in_local_currency
          }}</vs-td>
          <vs-td :data="data[indextr].local_currency">{{
            data[indextr].local_currency
          }}</vs-td>
          <vs-td :data="data[indextr].tax_code">{{
            data[indextr].tax_code
          }}</vs-td>
          <vs-td :data="data[indextr].special_g_l_ind">{{
            data[indextr].special_g_l_ind
          }}</vs-td>
          <vs-td :data="data[indextr].text">{{
            data[indextr].text
          }}</vs-td>
          <vs-td :data="data[indextr].g_l_account">{{
            data[indextr].g_l_account
          }}</vs-td>
          <vs-td :data="data[indextr].company_code">{{
            data[indextr].company_code
          }}</vs-td>
          <vs-td :data="data[indextr].trading_partner">{{
            data[indextr].trading_partner
          }}</vs-td>
          <vs-td :data="data[indextr].profit_center">{{
            data[indextr].profit_center
          }}</vs-td>
          <vs-td :data="data[indextr].clearing_doc">{{
            data[indextr].clearing_doc
          }}</vs-td>
          <vs-td :data="data[indextr].account">{{
            data[indextr].account
          }}</vs-td>
          <vs-td :data="data[indextr].account_type">{{
            data[indextr].account_type
          }}</vs-td>
          <vs-td :data="data[indextr].reserved_with">{{
            data[indextr].reserved_with
          }}</vs-td>
          <vs-td :data="data[indextr].discount_1_due_date_symbol">{{
            data[indextr].discount_1_due_date_symbol
          }}</vs-td>
          <vs-td :data="data[indextr].discount_percentage_1">{{
            data[indextr].discount_percentage_1
          }}</vs-td>
          <vs-td :data="data[indextr].discount_percentage_2">{{
            data[indextr].discount_percentage_2
          }}</vs-td>
          <vs-td :data="data[indextr].transaction_code">{{
            data[indextr].transaction_code
          }}</vs-td>
          <vs-td :data="data[indextr].transaction_type">{{
            data[indextr].transaction_type
          }}</vs-td>
          <vs-td :data="data[indextr].terms_of_payment">{{
            data[indextr].terms_of_payment
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startVendorBillDate: {
      type: Date,
    },
    endVendorBillDate: {
      type: Date,
    },
    openKeyDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    vendorCodeIDs: {
      type: Array,
    },
    vendorCodeNames: {
      type: Array,
    },
    paymentDocStatusIDs: {
      type: Array,
    },
    paymentDocStatusNames: {
      type: Array,
    },
    billDocStatusIDs: {
      type: Array,
    },
    billDocStatusNames: {
      type: Array,
    },
    docStatusIDs: {
      type: Array,
    },
    docStatusNames: {
      type: Array,
    },
    groupingAgingIDs: {
      type: Array,
    },
    groupingAgingNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportAPAging.tableData,
      total: (state) => state.reportAPAging.total,
      totalPerPage: (state) => state.reportAPAging.totalPerPage,
      totalPage: (state) => state.reportAPAging.totalPage,
      totalSearch: (state) => state.reportAPAging.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ getTableData: 'reportAPAging/getAPAgingReport' }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'username',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
        vendor_code:
          this.vendorCodeIDs.length == 0
            ? undefined
            : this.vendorCodeIDs.join(','),
        vendor_payment_doc_status:
          this.paymentDocStatusIDs.length == 0
            ? undefined
            : this.paymentDocStatusIDs.join(','),
        vendor_bill_dn_doc_status:
          this.billDocStatusIDs.length == 0
            ? undefined
            : this.billDocStatusIDs.join(','),
        doc_status:
          this.docStatusIDs.length == 0
            ? undefined
            : this.docStatusIDs.join(','),
        start_vendor_bill_date:
          this.startVendorBillDate == null
            ? undefined
            : moment(this.startVendorBillDate).format('YYYY-MM-DD'),
        end_vendor_bill_date:
          this.endVendorBillDate == null
            ? undefined
            : moment(this.endVendorBillDate).format('YYYY-MM-DD'),
        open_key_date:
          this.openKeyDate == null
            ? undefined
            : moment(this.openKeyDate).format('YYYY-MM-DD'),
        grouping_aging:
          this.groupingAgingIDs.length == 0
            ? undefined
            : this.groupingAgingIDs.join(','),
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        'ARTRANS_REPORT_' +
        (this.territoryNames.length > 0
          ? this.territoryNames.join('-')
          : 'all') +
        '_';

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          'DOC_DATE_' +
          moment(this.startDocDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endDocDate).format('YYYY-MM-DD') +
          '_';
      }

      if (this.startPostingDate || this.endPostingDate) {
        'POSTING_DATE_' +
          moment(this.startPostingDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endPostingDate).format('YYYY-MM-DD') +
          '_';
      }

      if (this.startDueDate || this.endDueDate) {
        'DUE_DATE_' +
          moment(this.startDueDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endDueDate).format('YYYY-MM-DD') +
          '_';
      }

      fileTitle +=
        'OPEN_KEY_DATE_' + moment(this.openKeyDate).format('YYYY-MM-DD');

      this.$http
        .get(this.baseUrl + '/export', {
          params: {
            territory_ids: this.territoryIDs,
            start_posting_date: this.startPostingDate
              ? moment(this.startPostingDate).format('YYYY-MM-DD')
              : null,
            end_posting_date: this.endPostingDate
              ? moment(this.endPostingDate).format('YYYY-MM-DD')
              : null,
            start_doc_date: this.startDocDate
              ? moment(this.startDocDate).format('YYYY-MM-DD')
              : null,
            end_doc_date: this.endDocDate
              ? moment(this.endDocDate).format('YYYY-MM-DD')
              : null,
            start_due_date: this.startDueDate
              ? moment(this.startDueDate).format('YYYY-MM-DD')
              : null,
            end_due_date: this.endDueDate
              ? moment(this.endDueDate).format('YYYY-MM-DD')
              : null,
            open_key_date: this.openKeyDate
              ? moment(this.openKeyDate).format('YYYY-MM-DD')
              : null,
            file: file,
            title: fileTitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == 'error') {
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: resp.message,
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          } else {
            this.$vs.notify({
              color: 'success',
              title: 'Processing',
              text: resp.message,
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
